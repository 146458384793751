import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Summer from 'images/blogs/blog-4.jpg'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/paano-ienjoy-ang-summer-with-digipay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Paano I-Enjoy ang Summer With Digipay',
  },
  {
    property: 'og:description',
    content:
      'Alamin kung paano magkakaroon ng mas pinasayang summer gamit ang Digipay app!',
  },
  {
    property: 'og:image',
    content: Summer,
  },
]

const EnjoySummer = () => (
  <Layout>
    <Helmet
      title="Paano I-Enjoy Ang Summer With Digipay"
      meta={SITE_META}
      fontWeight="bold"
    >
      <html lang="en" />
    </Helmet>
    <Row flexJustify="center" m="30px">
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3">
        <Image width="100%" mb="sp3" src={Summer} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          PAANO I-ENJOY ANG SUMMER WITH DIGIPAY
        </Heading>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Hassle-Free Flight Payment
          </Heading>
          <Text lineHeight="1.5">
            Pila na nga sa seat sale, pila pa din ba sa pagbayad? Digipay says
            NO. Dahil pwede ka nang magbayad ng vacation flights mo ngayong
            summer sa Digipay app, mas madami ka ng time mag-enjoy with your
            friends and family. Magbayad habang nagchi-chill sa bahay using
            Digipay!
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Less Init, Less Sungit
          </Heading>
          <Text lineHeight="1.5">
            Hindi na iinit ang ulo mo dahil hindi mo na kailangang dumaan sa
            matinding sikat ng araw para lang makapagbayad ng kuryente, tubig,
            cable, at iba pang mga bayarin sa bahay. Sa Digipay, kaya mo nang
            bayaran lahat ng ‘yan gamit lang ang iyong mobile phone.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Health Mo? I-Healthguard
          </Heading>
          <Text lineHeight="1.5">
            Dahil uso ang heat stroke, siguraduhing protektado ka at ang pamilya
            mo ngayong summer with an affordable health insurance - HEALTHGUARD.
            Sa halagang P100, may Daily Hospital Income (minimum 3 consecutive
            days of confinement) at Accidental Death benefits ka nang makukuha.
            Bukas ito para sa 18 hanggang 64 taong gulang kaya pwedeng-pwede
            gawing pang pamilya.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            GG Well Paid
          </Heading>
          <Text lineHeight="1.5">
            Plano mo bang magkulong sa bahay at mag-games lang during your
            summer break? Hindi mo na kailangang lumayo para lang bumili ng
            gaming pins. Madami ka nang pwedeng bilhin sa E-Pins section ng
            Digipay app. May Garena, Level Up, Mobile Legends, Cubizone, Hold’em
            PH, Lead Hope, MOL, Onigiri, at marami pang iba.
          </Text>
        </Section>
        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Shopping Galore? Shopping Pa More
          </Heading>
          <Text lineHeight="1.5">
            Sa init ng panahon, siguradong mapapa-shopping ka na lang sa mall.
            Siguradong gamit na gamit ang credit card mo kung shopping is life
            ka. No hassle ang pagbili ng bag, make up, shoes, at clothes pero
            dapat no hassle din ang pagbayad mo. Gamit ang Digipay app, ilang
            click lang sa mobile phone at bayad na ang credit card bill mo.
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)
export default EnjoySummer
